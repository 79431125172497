import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["system"],
  "status": ["completed"],
  "title": "Buswatch",
  "subtitle": "Using Rejseplanen API to show customized bus time for a specific bus stop",
  "description": "",
  "years": ["2018", "2019"],
  "institutions": ["idemolab"],
  "location": "cph",
  "keywords": {
    "topics": ["iot", "api"],
    "tools": ["python", "adobe xd"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Image of the Stand`}</h2>
    <h2>{`Description`}</h2>
    <p>{`Keep time of a specific bus stop and bus`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      